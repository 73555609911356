<template>
  <div
    class="flex flex-col"
    :class="[cardShadow ? 'border border-gray-200 shadow' : '', bgColor]"
  >
    <slot name="card-image">
      <!-- ToDo: make for srcset  -->
      <div v-if="cardImage?.src" class="aspect-w-16 aspect-h-9 relative">
        <LazyImg
          src="/images/lz.png"
          class="w-full object-cover"
          :data-src="cardImage.src"
          :alt="cardImage.alt"
        />
      </div>
    </slot>
    <div
      :class="
        borderColor
          ? ' border-t-6 ' +
            getCssClass(
              `border-brand-${borderColor}-300`,
              `border-int-${borderColor}-02`
            )
          : ''
      "
    >
      <slot name="card-header">
        <CardHeader v-if="cardHeader">
          <h3 class="text-base">{{ cardHeader }}</h3>
        </CardHeader>
      </slot>

      <div class="card-body">
        <div v-if="cardTitle || cardSubtitle" class="pb-4">
          <h2 v-if="cardTitle" class="h5 leading-tight lg:leading-7">
            {{ cardTitle }}
          </h2>
          <h3
            v-if="cardSubtitle"
            class="text-base font-bold leading-6 mt-1"
            :class="getCssClass('text-brand-grey-600', 'text-int-grey-02')"
          >
            {{ cardSubtitle }}
          </h3>
        </div>
        <slot
          ><span class="pt-4">{{ cardBody }}</span></slot
        >
      </div>
    </div>
    <CardFooter v-if="$slots.cardFooter || cardFooter">
      <slot name="cardFooter">
        {{ cardFooter }}
      </slot>
    </CardFooter>
  </div>
</template>

<script>
import useTheme from '~/composables/theme/useTheme'
export default {
  name: 'StaticCard',
  props: {
    cardHeader: {
      type: String,
      default: ''
    },
    cardTitle: {
      type: String,
      default: ''
    },
    cardSubtitle: {
      type: String,
      default: ''
    },
    cardBody: {
      type: String,
      default: ''
    },
    cardFooter: {
      type: String,
      default: ''
    },
    cardImage: {
      type: Object,
      default: () => {}
    },
    borderColor: {
      type: String,
      default: ''
    },
    cardShadow: {
      type: Boolean,
      default: true
    },
    bgColor: {
      type: String,
      default: 'bg-white'
    }
  },
  setup() {
    const { getCssClass } = useTheme()
    return { getCssClass }
  }
}
</script>
