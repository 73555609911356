<template>
  <footer class="flex items-center px-6 pb-6 mt-auto">
    <slot></slot>
  </footer>
</template>

<script>
export default {
  name: 'CardFooter'
}
</script>
